body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
  text-align: center;
  /* Центрирует текст */
  padding: 10px;
  /* Добавляет отступ */
  background-color: #f8f9fa;
  /* Фон для выделения */
  color: #6c757d;
  /* Цвет текста */
  font-size: 14px;
  /* Размер текста */
}

.footer p {
  margin: 5px 0;
  /* Отступ между строками */
}