.profile-icon {
    background-color: #f0f0f0;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
}

.avatar {
    width: 120px;
    height: 120px;
    border-width: 2px;
    border-color: #000;
    object-fit: cover;
    object-position: center 20%;

}

.icons {
    font-size: 2em
}

.heda {
    color: #323232;
    display: flex;
    justify-content: center;

}

.links a {
    color: #323232;
    text-decoration: none;

}